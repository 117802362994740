html,
body {
  height: 100%;
  overflow-y: hidden;
}

.root {
  height: 100%;
  overflow-y: hidden;
}

.page {
  height: 100%;
  /* overflow-y: hidden; */
  display: flex;
  flex-direction: row;
}

.MuiInput-root {
  height: 100%;
}

div.nodes {
  padding: 8pt;
  overflow-y: scroll;
  min-height: 100%;
  width: 40%;
  flex: 1;
  flex-wrap: nowrap;
}

.nodeControls {
  padding: 8pt;
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}

.controlsSection {
  padding: 24pt;
  display: flex;
  flex-direction: column;
}

div.listNode {
  padding: 8pt;
  margin-bottom: 8pt;
  width: 80%;
  overflow: unset;
}

.listNode__dragging.listNode__dragging {
  background-color: lightgoldenrodyellow;
}

.listNode__selected.listNode__selected {
  background-color: lightblue;
}

.optionTable.optionTable {
  overflow-y: initial;
}

.MuiInput-inputMultiline {
  height: 100% !important;
  overflow-y: auto !important;
}

.JsonDisplay-container {
  flex: 1;
  width: 20%;
  padding: 12pt;
}

.JsonDisplay-errors {
  display: block;
  border: red solid 1px;
  border-radius: 10pt;
  padding: 10pt;
  margin-bottom: 5pt;
}

.JsonDisplay-errors > span {
  color: red;
}

.JsonDisplay-success {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.JsonDisplay-success > span {
  color: green;
}

.JsonDisplay-text {
  width: 100%;
  height: 100%;
}
